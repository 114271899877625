/***
 *
 *   Pagination Arrow Buttons and current Page / Total Pages
 *
 *
 **********/

import React from 'react';
import { Card } from 'components/lib';

export function Pagination({ pagination, setPagination }) {
  const updatePage = (e, string) => {
    // Handles page input
    if (string === '') {
      let pageInput = Number(e.target.value);
      if (isNaN(pageInput)) pageInput = 1;
      if (pageInput < 1) pageInput = 1;
      if (pageInput > pagination.totalPages) pageInput = pagination.totalPages;
      setPagination({
        page: pageInput,
        limit: pagination.limit,
        totalPages: pagination.totalPages,
      });
    }

    // Handles page increase button
    if (pagination.page !== pagination.totalPages && string === 'up')
      setPagination({
        page: pagination.page + 1,
        limit: pagination.limit,
        totalPages: pagination.totalPages,
      });

    // Handles page decrease button
    if (pagination.page !== 1 && string === 'down')
      setPagination({
        page: pagination.page - 1,
        limit: pagination.limit,
        totalPages: pagination.totalPages,
      });
  };
  return (
    <Card>
      <div className="pagination">
        <span tabIndex={1} onClick={(e) => updatePage(e, 'down')}>
          &#10094;
        </span>
        {
          <div className="pageTotal">
            {' '}
            <span>
              <input
                onBlur={(e) => {
                  e.target.value = '';
                }}
                id="page_input"
                onChange={(e) => updatePage(e, '')}
                aria-label="page-input"
                placeholder={pagination.page}
              ></input>
            </span>
            / {pagination.totalPages}
          </div>
        }
        <span tabIndex={1} onClick={(e) => updatePage(e, 'up')}>
          &#10095;
        </span>
      </div>
    </Card>
  );
}
