/***
 *
 *   ADMIN ANALYTICS
 *   Manage all users signed up to your application
 *
 **********/

import React, { useContext, useState, useEffect } from 'react';
import Axios from 'axios';
import { apiRoot } from 'settings';
import { ViewContext, AuthContext, Card, Table, usePermissions, useAPI } from 'components/lib';

export function AdminUsers(props) {
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const permissions = usePermissions();
  const users = useAPI('/admin/users');
  const [usersState, setUsersState] = useState([]);

  useEffect(() => {
    if (!users.loading) setUsersState(users.data);
  }, [users]);

  function editUser(data, callback) {
    // only admin can change owner permission
    if (permissions.data.list.findIndex((x) => x.value === 'owner') < 0)
      permissions.data.list.push({ value: 'owner', label: 'owner' });

    context.modal.show(
      {
        title: 'Edit User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          accountId: {
            type: 'hidden',
            value: data.account_id,
          },
          firstName: {
            label: 'First Name',
            type: 'text',
            value: data.first_name,
            requried: true,
            maxLength: 255,
          },
          lastName: {
            label: 'Last Name',
            type: 'text',
            value: data.last_name,
            requried: true,
            maxLength: 64,
          },
          email: {
            label: 'Email',
            type: 'email',
            value: data.email,
            required: true,
            maxLength: 64,
          },
          permission: {
            label: 'Permission',
            type: 'select',
            default: data.permission,
            options: permissions.data.list,
            required: true,
          },
        },
        buttonText: 'Save',
        url: '/admin/user',
        method: 'PATCH',
      },
      (res) => {
        context.notification.show(
          res.firstName.value + ' ' + res.lastName.value + ' was updated',
          'success',
          true
        );
        const display = {
          id: res.id,
          account_id: res.accountId,
          first_name: res.firstName,
          last_name: res.lastName,
          email: res.email,
          permission: res.permission,
        };

        callback(display);
      }
    );
  }

  function deleteUser(data, callback) {
    context.modal.show(
      {
        title: 'Delete User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          accountId: {
            type: 'hidden',
            value: data.account_id,
          },
        },
        buttonText: 'Delete User',
        text: 'Are you sure you want to delete ' + data.first_name + ' ' + data.last_name + '?',
        url: '/admin/user',
        method: 'DELETE',
      },
      () => {
        context.notification.show(
          data.first_name + ' ' + data.last_name + ' was deleted',
          'success',
          true
        );
        callback();
      }
    );
  }

  async function impersonateUser(data) {
    const res = await Axios.post(apiRoot + '/admin/user/impersonate', {
      id: data.id,
    });
    authContext.signin(res);
  }

  return (
    <Card>
      <Table
        search
        data={usersState}
        loading={usersState?.loading}
        hide={['id', 'account_id']}
        badge={{ col: 'permission', color: 'blue' }}
        actions={{
          edit: editUser,
          delete: deleteUser,
          email: true,
          custom: [{ icon: 'log-in', action: impersonateUser, title: 'Impersonate User' }],
        }}
      />
    </Card>
  );
}
