/***
 *
 *   Edit Step
 *   Enables an owner to edit a  step
 *
 **********/
import React from 'react';
import { Button, Heading, Spinner } from '@chakra-ui/react';
import { useAPI, Card, Form, TitleRow } from 'components/lib';
import { NavLink, useHistory } from 'react-router-dom';

import Style from '../../../components/button/button.module.scss';

export function EditStep(props) {
  const { stepId } = props.computedMatch.params;
  const { checklistId } = props.computedMatch.params;
  const history = useHistory();
  const steps = useAPI(`/checklist/${checklistId}/steps`);
  const step = steps.data?.find(({ id }) => id === stepId);
  const sortOptions = steps.data?.map((step, index) => {
    return { value: index + 1, label: index + 1 };
  });
  return (
    <>
      <TitleRow>
        <Heading variant="h5" color="orange">
          Edit Step
        </Heading>
        <Button
          as={NavLink}
          exact
          to={`/dashboard/checklist/${step?.checklist_id}/steps`}
          variant="titleRowButton"
        >
          Back to Steps
        </Button>
      </TitleRow>

      <Card>
        {step && sortOptions ? (
          <Form
            method={'PATCH'}
            url={`/step/${stepId}`}
            data={{
              checklistId: {
                type: 'hidden',
                value: step?.checklist_id,
              },
              item: {
                label: 'Step',
                type: 'text',
                value: step?.item,
                placeholder: 'Short description of the action the user should take',
                autocomplete: 'off',
                required: true,
                maxLength: 128,
              },
              link: {
                label:
                  'Link To (optional link to documentation, a specific page in your dashboard, etc.)',
                type: 'text',
                value: step?.link,
                placeholder: 'https://example.com/path',
                autocomplete: 'off',
                maxLength: 256,
              },
              linkTargetBlank: {
                label: 'Open Link in New Tab?',
                type: 'switch',
                value: step?.link_target_blank,
                default: false,
                dynamicallyUpdate: {
                  inputIds: ['link'], // choose what inputs need to change when this input gets updated
                },
              },
              reminderSubject: {
                label: 'Email Reminder Subject',
                type: 'text',
                value: step?.reminder_subject || '',
                placeholder: 'Email reminder subject',
                autocomplete: 'off',
                maxLength: 50,
                dynamicallyUpdate: {
                  inputIds: ['reminderSubject', 'reminderHtml', 'reminderText'], // choose what inputs need to change when this input gets updated
                },
              },
              reminderText: {
                label: 'Email Reminder Plain Text Message',
                type: 'textarea',
                value: step?.reminder_text || '',
                placeholder: 'Email reminder plain text message',
                autocomplete: 'off',
                maxLength: 10000,
                dynamicallyUpdate: {
                  inputIds: ['reminderText', 'reminderHtml', 'reminderSubject'], // choose what inputs need to change when this input gets updated
                },
              },
              reminderHtml: {
                label: 'Email Reminder HTML Message',
                type: 'richtext',
                value: step?.reminder_html || '',
                autocomplete: 'off',
                maxLength: 10000,
                dynamicallyUpdate: {
                  inputIds: ['reminderHtml', 'reminderText', 'reminderSubject'], // choose what inputs need to change when this input gets updated
                },
              },
              reminderCTAText: {
                label: 'Email Reminder Call to Action Button Text',
                type: 'text',
                value: step?.reminder_cta_text,
                placeholder: 'Call to action button text',
                autocomplete: 'off',
                maxLength: 128,
                dynamicallyUpdate: {
                  inputIds: ['reminderCTAText', 'reminderCTALink'], // choose what inputs need to change when this input gets updated
                },
              },
              reminderCTALink: {
                label: 'Email Reminder Call to Action Button Link',
                type: 'text',
                value: step?.reminder_cta_link,
                placeholder: 'https://www.example.com/dashboard',
                autocomplete: 'off',
                maxLength: 256,
                dynamicallyUpdate: {
                  inputIds: ['reminderCTAText', 'reminderCTALink'], // choose what inputs need to change when this input gets updated
                },
              },
              sortOrder: {
                label: 'Sort Order',
                type: 'select',
                default: step?.sort_order,
                required: true,
                options: sortOptions,
                restrictWidth: true,
              },
              checkedByDefault: {
                label:
                  'Checked by Default (boost engagement by starting your checklist with tasks the user has already completed)',
                type: 'switch',
                value: step?.checked_by_default,
                default: false,
              },
              active: {
                label: 'Active',
                type: 'switch',
                value: step?.active,
                default: true,
              },
              stepList: {
                value: steps.data?.filter((step) => step.id !== stepId),
                type: 'hidden',
              },
            }}
            buttonText={'Save'}
            callback={() => history.push(`/dashboard/checklist/${step?.checklist_id}/steps`)}
          />
        ) : (
          <div className={Style.loading}>
            <Spinner size="xl" color={'#ec4815'} />
          </div>
        )}
      </Card>
    </>
  );
}
