/***
 *
 *   SEARCH
 *   Search input field
 *
 *   PROPS
 *   callback: function executed on change and submit
 *
 **********/

import React, { useState } from 'react';
import ClassNames from 'classnames';
import Style from './search.module.scss';
import InputStyle from '../form/input/input.module.scss';

export function Search(props) {
  const [value, setValue] = useState(props.value || '');
  const css = ClassNames([Style.search, props.className]);

  return (
    <form className={css}>
      <input
        onBlur={(e) => {
          e.target.value = '';
        }}
        type="text"
        className={InputStyle.textbox}
        placeholder="Search"
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <input
        type="submit"
        value=""
        className={Style.btn}
        onClick={(e) => {
          e.preventDefault();
          props.callback(value);
        }}
      />
    </form>
  );
}
