/***
 *
 *  Manage Steps
 *   Enables an owner to manage the steps in a specific Checklist
 *
 **********/

import React, { useContext, useEffect, useState } from 'react';
import { Flex, Button, Heading, Spinner } from '@chakra-ui/react';
import { Table, Card, StepsNav, useAPI, ViewContext } from 'components/lib';
import { NavLink, useHistory } from 'react-router-dom';

export function Steps(props) {
  const { checklistId } = props.computedMatch.params;
  const checklist = useAPI(`/checklist/${checklistId}`);
  const steps = useAPI(`/checklist/${checklistId}/steps`);
  const [stepList, setStepList] = useState([]);
  const [checklistState, setChecklistState] = useState([]);
  const context = useContext(ViewContext);
  const history = useHistory();

  useEffect(() => {
    let loaded = true;
    if (loaded) {
      if (!steps.loading && !checklist.loading) {
        setStepList(steps.data);
        setChecklistState(checklist.data);
      }
    }
    return () => {
      loaded = false;
    };
  }, [steps, checklist]);

  const stepsData = stepList?.map((step) => {
    return {
      stepId: step.id,
      step: step.item,
      active: step.active,
      'checked by default': step.checked_by_default,
      sort_order: step.sort_order,
      link: step.link,
      linkTargetBlank: step.link_target_blank,
      reminderSubject: step.reminder_subject,
      reminderText: step.reminder_text,
      reminderHtml: step.reminder_html,
      reminderCTAText: step.reminder_cta_text,
      reminderCTALink: step.reminder_cta_link,
      checklistId: step.checklist_id,
    };
  });

  const editStep = (data) => {
    history.push(`/dashboard/step/${checklistId}/${data.stepId}`);
  };

  const deleteStep = (data, callback) => {
    const updatedChecklists = stepList?.filter((step) => step.id !== data.stepId);
    context.modal.show(
      {
        form: {
          checklistId: {
            type: 'hidden',
            value: checklistId,
          },
          stepList: {
            type: 'hidden',
            value: updatedChecklists,
          },
        },
        title: 'Delete Step',
        buttonText: 'Delete Step',
        text: 'Do you want to delete ' + data.step + '?',
        url: `/step/${data.stepId}`,
        method: 'DELETE',
      },
      (res) => {
        if (res.data) {
          updatedChecklists.map((step, index) => {
            return (step.sort_order = index + 1);
          });
          setStepList(updatedChecklists);
        }
        context.notification.show(data.step + ' was deleted', 'success', true);
      }
    );
  };

  return (
    <>
      <StepsNav checklistId={checklistId} />
      <Flex
        mt="30px"
        justify="space-between"
        align={{ base: 'flex-start', md: 'center' }}
        direction={{ base: 'column', md: 'row' }}
        mb="15px"
      >
        {checklistState?.checklist_name ? (
          <Heading variant="h5" color="orange">
            Manage Steps for {checklistState?.checklist_name}
          </Heading>
        ) : (
          <Spinner size="xl" color={'#ec4815'} />
        )}

        <Button
          as={NavLink}
          to={{
            pathname: `/dashboard/add/${checklistId}`,
            state: stepList?.length || 0,
          }}
          variant="titleRowButton"
        >
          Add Step
        </Button>
      </Flex>

      {stepList && checklistState && (
        <Card className="manage-steps">
          <Table
            className="restict-width"
            show={['sort_order', 'step', 'active', 'checked by default']}
            data={stepsData}
            actions={{
              custom: [{ icon: 'edit', action: editStep, title: 'Edit Step' }],
              delete: deleteStep,
              testEmail: true,
            }}
          />
        </Card>
      )}
    </>
  );
}
