/***
 *
 *   USERS
 *   Enables an owner to manage the users in their application
 *
 **********/

import React, { useContext, useState, useEffect } from 'react';
import {
  ViewContext,
  Card,
  AccountNav,
  Table,
  useAPI,
  usePermissions,
  TitleRow,
} from 'components/lib';
import { Button, Heading } from '@chakra-ui/react';

export function Users(props) {
  const context = useContext(ViewContext);
  const permissions = usePermissions();
  const users = useAPI('/account/users');
  const [usersState, setUsersState] = useState([]);

  useEffect(() => {
    if (!users.loading) setUsersState(users.data);
  }, [users]);

  function invite(data) {
    context.modal.show({
      title: 'Add User',
      form: {
        email: {
          label: 'Email',
          type: 'email',
          required: true,
          maxLength: 64,
        },
      },
      buttonText: 'Send Invite',
      text: 'To invite more than one user, seperate the emails with a comma',
      url: '/invite',
      method: 'POST',
    });
  }

  function editUser(data, callback) {
    context.modal.show(
      {
        title: 'Edit User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          firstName: {
            label: 'First Name',
            type: 'text',
            required: true,
            value: data.first_name,
            errorMessage: 'Please enter a first name',
            maxLength: 255,
          },
          lastName: {
            label: 'Last Name',
            type: 'text',
            required: true,
            value: data.last_name,
            errorMessage: 'Please enter a last name',
            maxLength: 64,
          },
          email: {
            label: 'Email',
            type: 'email',
            value: data.email,
            required: true,
            maxLength: 64,
          },
          permission: {
            label: 'Role',
            type: 'select',
            options: permissions.data.list,
            default: data.permission,
          },
        },
        buttonText: 'Save',
        url: '/user',
        method: 'PATCH',
      },
      (res) => {
        context.notification.show(
          res.firstName.value + ' ' + res.lastName.value + ' was updated',
          'success',
          true
        );
        const display = {
          id: res.id,
          first_name: res.firstName,
          last_name: res.lastName,
          email: res.email,
          permission: res.permission,
        };
        callback(display);
      }
    );
  }

  function deleteUser(data, callback) {
    context.modal.show(
      {
        title: 'Delete User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
        },
        buttonText: 'Delete User',
        text: 'Are you sure you want to delete ' + data.first_name + ' ' + data.last_name + '?',
        url: '/user',
        method: 'DELETE',
      },
      () => {
        context.notification.show(
          data.first_name + ' ' + data.last_name + ' was deleted',
          'success',
          true
        );
        callback();
      }
    );
  }

  return (
    <>
      <AccountNav />
      <TitleRow>
        <Heading color="orange" variant="h5">
          Manage Users
        </Heading>
        <Button variant="titleRowButton" onClick={invite}>
          Add User
        </Button>
      </TitleRow>

      <Card>
        <Table
          search
          className="restrict-width"
          data={usersState}
          loading={users?.loading}
          show={['first_name', 'last_name', 'email', 'date_created', 'last_login', 'permission']}
          badge={{ col: 'permission', color: 'blue' }}
          actions={{
            edit: editUser,
            delete: deleteUser,
            email: true,
          }}
        />
      </Card>
    </>
  );
}
