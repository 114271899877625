import React, { useState, useContext } from 'react';
import { Container, Heading, Flex, Button } from '@chakra-ui/react';
import { ViewContext, Form, Icon } from 'components/lib';

export function Feedback(props) {
  // context & state
  const context = useContext(ViewContext);
  const [showForm, setShowForm] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [rating, setRating] = useState(null);

  // maps
  const icons = ['smile', 'meh', 'frown'];
  const ratings = ['positive', 'neutral', 'negative'];
  const colors = ['#8CC57D', '#F0AA61', '#D95565'];

  function saveRating(rating) {
    setRating(rating);
    setShowComments(true);
  }

  return (
    <>
      {showForm ? (
        <Container
          right={{ base: '0px', sm: '20px' }}
          position="fixed"
          bottom="35px"
          variant="card"
          maxW={{ base: '350px', sm: '370px' }}
        >
          <Heading
            color="white"
            fontSize="20px"
            pb="10px"
            bgColor="transparent"
            borderBottom="1px dotted lightGray"
            variant="cardTitle"
          >
            Enjoying OnboardList?
          </Heading>
          <Button
            width="40px"
            position="absolute"
            top="15px"
            right="10px"
            opacity="0.6"
            transition="opacity 0.2s ease-in-out"
            type="button"
            color="white"
            border="none"
            _focus={{ outline: 'none' }}
            _hover={{ opacity: '1' }}
            onClick={(e) => setShowForm(false)}
          >
            <Icon image="x" size={20} />
          </Button>

          <>
            <Flex textAlign="center" justifyContent="space-evenly" mb="15px">
              {icons.map((icon, index) => {
                return (
                  <Button
                    height="70px"
                    width="70px"
                    border="none"
                    color={index === rating ? colors[index] : 'white'}
                    onClick={(e) => saveRating(index)}
                    key={index}
                    _focus={{ outline: 'none' }}
                  >
                    <Icon image={icon} size={60} />
                  </Button>
                );
              })}
            </Flex>
            {showComments && (
              <Form
                data={{
                  rating: {
                    type: 'hidden',
                    value: ratings[rating],
                  },
                  comment: {
                    label: `Tell us about your experience`,
                    type: 'textarea',
                  },
                }}
                method="POST"
                url="/feedback"
                updateOnChange
                buttonText="Send Feedback"
                onChange={(e) => {
                  return false;
                }}
                callback={(e) => {
                  setShowForm(false);
                  context.notification.show(
                    'Thank you, your feedback is most appreciated.',
                    'success',
                    true,
                    'toast',
                    'heart'
                  );
                }}
              />
            )}
          </>
        </Container>
      ) : (
        <Button
          position="fixed"
          border="none"
          right="24px"
          bottom="24px"
          width="48px"
          height="48px"
          zIndex={9}
          cursor="pointer"
          textAlign="center"
          lineHeight="48px"
          borderRadius="100%"
          bgColor="lightRed"
          onClick={(e) => setShowForm(true)}
        >
          <Icon image="heart" color="white" />
        </Button>
      )}
    </>
  );
}
