/***
 *
 *   ADMIN ACCOUNTS
 *   Manage all accounts signed up to your application
 *
 **********/

import React, { useContext, useState, useEffect } from 'react';
import { ViewContext, Card, Table, usePlans, useAPI } from 'components/lib';

export function AdminAccounts(props) {
  const context = useContext(ViewContext);
  const plans = usePlans();
  const accounts = useAPI('/admin/accounts');
  const [accountsState, setAccountsState] = useState([]);

  useEffect(() => {
    if (!accounts.loading) setAccountsState(accounts.data);
  }, [accounts]);

  function editAccount(data, callback) {
    context.modal.show(
      {
        title: 'Edit Account',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          email: {
            label: 'Email',
            type: 'email',
            required: true,
            value: data.email,
            maxLength: 64,
          },
          plan: {
            label: 'Plan',
            type: 'select',
            options: plans.data.list,
            default: data.plan,
            required: true,
          },
          active: {
            label: 'Status',
            type: 'select',
            default: data.active,
            options: [
              { value: 1, label: 'Active' },
              { value: 0, label: 'Cancelled' },
            ],
            required: true,
          },
          date_deleted: {
            label: 'Re-Open Account',
            type: 'select',

            options: [{ value: null, label: 'Re-Open' }],
          },
        },
        buttonText: 'Save',
        url: '/admin/account',
        method: 'PATCH',
      },
      (res) => {
        context.notification.show(data.name + ' was updated', 'success', true);
        callback(res);
      }
    );
  }

  function deleteAccount(data, callback) {
    context.modal.show(
      {
        title: 'Delete Account',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
        },
        buttonText: 'Delete Account',
        text:
          'Are you sure you want to delete ' + (data.name ? data.name : data.email) + "'s account?",
        url: '/admin/account',
        method: 'DELETE',
      },
      () => {
        context.notification.show(data.name + ' was deleted', 'success', true);
        callback();
      }
    );
  }

  return (
    <Card loading={false}>
      <Table
        search
        hide={['id']}
        data={accountsState}
        loading={accountsState?.loading}
        badge={{ col: 'plan', color: 'blue' }}
        actions={{ edit: editAccount, delete: deleteAccount, email: true }}
      />
    </Card>
  );
}
