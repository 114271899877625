import React, { useState, useContext } from 'react';
import Moment from 'react-moment';
import { Button, Badge, ViewContext } from 'components/lib';
import Style from './table.module.scss';
import { apiRoot } from '../../settings';

export function Body({ data, actions, show, hide, badge }) {
  if (data?.length) {
    return (
      <tbody>
        {data.map((row, index) => {
          return (
            <Row key={index} data={row} show={show} hide={hide} badge={badge} actions={actions} />
          );
        })}
      </tbody>
    );
  }

  return (
    <tbody>
      <tr>
        <td colSpan="10">No results found</td>
      </tr>
    </tbody>
  );
}

export function Row({ data, actions, badge, hide, show }) {
  const [loading, setLoading] = useState(false);
  const context = useContext(ViewContext);
  const sendReminderTest = () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));
    fetch(`${apiRoot}/reminder`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ` + user.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        stepId: data.stepId,
        email: user.email,
      }),
    }).then(() => {
      setLoading(false);
      context.notification.show(`Test email sent to ${user.email}`, 'success', true);
    });
  };

  let row = { ...data };
  row.actions = actions;
  const hasActions = Object.values(row.actions).some((x) => x !== undefined);
  return (
    <tr data-id={data.id}>
      {Object.keys(row).map((cell, index) => {
        // actions
        if (cell === 'actions') {
          if (hasActions) {
            return (
              <td key={index} className={Style.actions}>
                {row.actions?.custom?.map((action, i) => {
                  return (
                    <Button
                      title={action.title}
                      key={i}
                      icon={action.icon}
                      action={() => action.action(row)}
                    />
                  );
                })}
                {row.actions.edit && (
                  <Button title={'Edit'} icon="edit" action={() => row.actions.edit(row)} />
                )}
                {row.actions.delete && (
                  <Button
                    title="Delete"
                    icon="trash"
                    action={() => row.actions.delete(row)}
                    disabled={row.permission === 'owner' ? true : false}
                  />
                )}
                {row.actions.email && (
                  <a href={`mailto:${row.email}`} target="_blank" rel="noopener noreferrer">
                    <Button title={'Send Email'} icon="mail" />
                  </a>
                )}
                {row.actions.testEmail && (
                  <Button
                    title={'Send Test Email'}
                    loading={loading}
                    icon="mail"
                    action={() => sendReminderTest()}
                  />
                )}
              </td>
            );
          } else return false;
        }

        // hide
        if (hide?.includes(cell)) return false;

        // show
        if (show && !show.includes(cell)) return false;

        let value = row[cell];

        // is date
        if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value))
          value = <Moment date={value} format="YYYY-MM-DD" />;

        // has badge
        if (badge && cell === badge.col && value) {
          return (
            <td key={index}>
              <Badge text={value} color={badge.color} />
            </td>
          );
        }

        // standard cell
        return <td key={index}>{value === true ? 'Yes' : value === false ? 'No' : value}</td>;
      })}
    </tr>
  );
}
