/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import React, { useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Axios from 'axios';
import { AuthContext, AppNav, HoverNav, Logo, Modal, Footer, History } from 'components/lib';
import { Flex, Box, Button, Link } from '@chakra-ui/react';
import { apiRoot, checklistId, checklistUrl } from 'settings';
import '../layout.scss'; // globals

export function AppLayout(props) {
  const { user, update, signout } = useContext(AuthContext);

  useEffect(() => {
    let script = document.getElementById('OnboardListScript');

    if (!script && user.permission !== 'admin') {
      script = document.createElement('script');
      script.async = true;
      script.id = 'OnboardListScript';
      script.src = checklistUrl;
      document.body.appendChild(script);
    }

    if (script && user.firstName && user.lastName) {
      script.onload = () => {
        window.OnboardList.initialize({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          checklistId,
        });
      };
    }
  }, [user]);

  const adminItems = [
    { label: 'Dashboard', link: '/admin' },
    { label: 'Feedback', link: '/admin/feedback' },
    { label: 'Accounts', link: '/admin/accounts' },
    { label: 'Users', link: '/admin/users' },
  ];

  const userItems = [
    { label: 'Dashboard', link: '/dashboard' },
    {
      label: 'Checklists',
      link: '/dashboard/checklists',
    },
    { label: 'Signups', link: '/dashboard/signups' },
    { label: 'Reminders', link: '/dashboard/reminders' },
    { label: 'Exclusions', link: '/dashboard/exclusions' },
    { label: 'Account', link: '/dashboard/account' },
  ];

  const appNavItems = user.permission === 'admin' ? adminItems : userItems;

  const createFirstChecklist = async (data) => {
    await Axios({
      method: 'POST',
      url: apiRoot + '/checklist',
      data: {
        name: 'Example Checklist',
        fromName: data.firstName + ' ' + data.lastName,
        replyEmail: user.email,
        exampleChecklist: true,
      },
    }).then(() => {
      History.push('/dashboard/checklists');
    });
  };

  const modalBody = {
    title: 'Welcome to OnboardList!',
    form: {
      firstName: {
        label: 'First name',
        type: 'text',
        value: '',
        placeholder: 'John',
        autocomplete: 'off',
        required: true,
      },
      lastName: {
        label: 'Last Name',
        type: 'text',
        value: '',
        placeholder: 'Smith',
        autocomplete: 'off',
        required: true,
      },
      companyName: {
        label: 'Company Name',
        type: 'text',
        value: '',
        placeholder: 'OnboardList',
        autocomplete: 'off',
        required: true,
      },
      email: {
        type: 'hidden',
        value: user.email,
      },
      newsletter: {
        type: 'hidden',
        value: true,
      },
    },
    hideCancel: true,
    buttonText: 'Complete Account',
    text:
      'When you submit the information below, our system will use it to create your first onboarding checklist.',
    url: '/account',
    method: 'PATCH',
    notification: false,
    callback: async (res) => {
      if (res.data) {
        await update(res.data.data);
        await createFirstChecklist(res.data.data);

        // re-initialize Widget after user is updated
        window.OnboardList.initialize({
          firstName: res.data.data.firstName,
          lastName: res.data.data.lastName,
          email: res.data.data.email,
          checklistId,
        });
      }
    },
  };

  return (
    <>
      {(!user.firstName || !user.lastName) && user.permission !== 'admin' && (
        <Modal {...modalBody} />
      )}

      <Flex
        bgColor="orange"
        color="white"
        width="100%"
        position="fixed"
        zIndex={4}
        height="72px"
        justify="space-between"
        alignItems="center"
      >
        <Box ml="40px" width="135px">
          <Logo white />
        </Box>
        {(user.firstName || user.lastName) && (
          <HoverNav
            icon="user"
            label={`${user.firstName}${user.firstName && user.lastName && ' '}${user.lastName}`}
          >
            <Button
              type="button"
              width="100%"
              variant="plain"
              borderBottom="1px solid lightGray"
              paddingBottom="10px"
            >
              <Link as={RouterLink} to="/dashboard/account" _focus={{ outline: 'none' }}>
                Account Settings
              </Link>
            </Button>
            <Button
              onClick={signout}
              type="button"
              variant="plain"
              width="100%"
              paddingTop="10px"
              _focus={{ outline: 'none' }}
            >
              Signout
            </Button>
          </HoverNav>
        )}
      </Flex>
      <Flex
        width="100%"
        justifyContent={{ base: 'center', md: 'initial' }}
        pt={{ base: '32px', md: 'none' }}
      >
        <AppNav type="popup" items={appNavItems} />
        <Box
          as="main"
          bgColor="lightGray"
          padding="75px 24px 24px 24px"
          minHeight="150vh"
          width="100%"
        >
          {<props.children {...props.data} />}
        </Box>
      </Flex>
      <Footer />
    </>
  );
}
