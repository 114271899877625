/***
 *
 *   Reminders
 *   Enables an owner to see reminder emails and webhooks for them
 *
 **********/

import React, { useState, useEffect } from 'react';
import { Table, Card, useAPI, TitleRow } from 'components/lib';
import { Button, Heading } from '@chakra-ui/react';
import Style from '../../components/button/button.module.scss';
import ClassNames from 'classnames';
import { useHistory } from 'react-router-dom';

export function Reminders() {
  const btnCss = ClassNames([Style.btn, Style.small]);
  const [remindersState, setReminders] = useState([]);
  const [checklistNames, setChecklistNames] = useState();
  const [checklist, setChecklist] = useState('');
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 25,
    totalPages: 0,
  });
  const history = useHistory();
  const reminders = useAPI(
    `/reminder?&page=${pagination.page}&limit=${pagination.limit}&search=${search}&checklist=${checklist}`
  );

  const viewDetails = (id) => {
    history.push(`/dashboard/reminders/${id}`);
  };

  remindersState.map((reminder) => {
    return (reminder[' '] = (
      <Button
        variant="orange"
        onClick={() => viewDetails(reminder.id)}
        type="button"
        className={btnCss}
      >
        View Details
      </Button>
    ));
  });

  const handleChecklist = (name, value) => {
    setChecklist(value);
    setSearch('');
  };

  useEffect(() => {
    if (reminders.data) {
      setReminders(reminders.data.remindersData);
      setPagination({
        page: pagination.page,
        limit: pagination.limit,
        totalPages: Math.ceil(reminders.data.remindersCount / pagination.limit),
      });
      setChecklistNames(reminders.data.checklistNames);
    }
  }, [reminders.data, pagination.page, pagination.limit]);

  const handleLimit = (name, value) => {
    // Handles limit per page (offset)
    let pageNumber = pagination.page;
    let newPageTotal = Math.ceil(reminders.data.remindersCount / value);

    if (pageNumber > newPageTotal && newPageTotal > 0) pageNumber = newPageTotal;
    setPagination({ limit: value, page: pageNumber });
  };

  return (
    <>
      <TitleRow>
        <Heading variant="h5" color="orange">
          Manage Reminder Emails Sent
        </Heading>
      </TitleRow>
      <Card>
        <Table
          reminders={!!remindersState?.length}
          handleChecklist={handleChecklist}
          checklistData={{ reminders: checklistNames, default: 'View All' }}
          loading={reminders.loading}
          className="reminder-table"
          pagination={pagination}
          setPagination={setPagination}
          setSearch={setSearch}
          handleLimit={handleLimit}
          show={['to', 'date', 'checklist', 'step', 'opens', 'clicks', ' ']}
          data={remindersState}
        />
      </Card>
    </>
  );
}
