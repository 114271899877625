/***
 *
 *   FORM
 *   Self-validating form that accepts an object for construction
 *   Read the full documentation on object formatting
 *   https://docs.usegravity.app/ui/form
 *
 *   PROPS
 *   data: the object containing your form data
 *   callback: function to be executed on successful submit
 *   url: url to send the form to (optional)
 *   method: HTTP request type
 *   redirect: url to redirect to after a successful submit (optional)
 *   buttonText: submit button text
 *   cancel: true/false to toggle a cancel button (optional)
 *
 **********/

import React, { useState, useEffect, useContext } from 'react';
import { Button, Box } from '@chakra-ui/react';
import Axios from 'axios';
import { apiRoot } from 'settings';

import {
  DateInput,
  FormHeader,
  TextInput,
  NumberInput,
  EmailInput,
  URLInput,
  PhoneInput,
  PasswordInput,
  HiddenInput,
  CardInput,
  Select,
  Switch,
  Fieldset,
  Link,
  ViewContext,
  History,
  Error,
} from 'components/lib';

import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';

import RichTextEditor from './richtextEditor/richtextEditor';

function Form(props) {
  // context & state
  const context = useContext(ViewContext);
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [processCreditCard, setProcessCreditCard] = useState(false);
  const [error, setError] = useState('');

  let valid = true;

  // inputs map
  const Inputs = {
    text: TextInput,
    textarea: TextInput,
    email: EmailInput,
    number: NumberInput,
    url: URLInput,
    date: DateInput,
    hidden: HiddenInput,
    phone: PhoneInput,
    password: PasswordInput,
    creditcard: CardInput,
    radio: Fieldset,
    select: Select,
    checkbox: Fieldset,
    selector: Fieldset,
    switch: Switch,
    header: FormHeader,
    link: Link,
  };

  useEffect(() => {
    // if the form is valid and using
    // live updates, refresh the form
    let canceled = false;

    if (!canceled) {
      if (valid && props.updateOnChange) {
        setForm(props.data);
      }

      // otherwise, only init if no form set
      else if (!form) {
        let data = { ...props.data };

        // init credit card
        if (data?.token) {
          data?.plan?.default === 'free' ? setProcessCreditCard(false) : setProcessCreditCard(true);
        }

        setForm(data);
      }
    }
    return () => {
      canceled = true;
    };
  }, [props, form, valid]);

  if (!form) return false;

  function update(input, value, valid) {
    let data = { ...form };
    // update input value & valid state
    data[input].value = value;
    data[input].valid = valid;

    // hide credit card input when selecting free plan
    if (props.data.token) {
      if (input === 'plan' && value === 'free') {
        setProcessCreditCard(false);
      } else if (input === 'plan' && value !== 'free') {
        setProcessCreditCard(true);
      }
    }

    // check if form needs to dynamically change 'required' value
    if (data[input].dynamicallyUpdate) {
      data[input].dynamicallyUpdate?.inputIds.forEach((inputId) => {
        if (!data[inputId].required && value) {
          data[inputId].required = true;
        }
        if ((data[inputId].required === true && !value) || value === 'Always show') {
          const inputHasValue = [];
          // check if any input has value in them
          data[inputId].dynamicallyUpdate?.inputIds.forEach((elem) => {
            if (data[elem].value) {
              inputHasValue.push(true);
            }
          });
          if (!inputHasValue?.length) {
            data[inputId].required = false;
            data[inputId].valid = valid;
          }
        }
      });
    }

    setForm(data);

    props.updateOnChange && props.onChange({ input: input, value: value, valid: valid });
    props.submitOnChange && submit();
  }

  function validate() {
    // loop over each input and check it's valid
    // show error if input is required and value is
    // blank, input validation will be executed on blur

    let errors = [];
    let data = { ...form };

    // loop the inputs
    for (let input in data) {
      // validate credit card
      if (input === 'token') {
        if (processCreditCard && data.token.value.error) {
          data.token.valid = false;
          errors.push(false);
        } else {
          data.token.valid = true;
        }
      } else {
        // standard input
        let inp = data[input];
        if (inp.value === undefined && inp.default) {
          data[input].value = inp.default;
        }

        if (inp.required) {
          if (!inp.value || inp.value === 'unselected') {
            inp.valid = false;
            errors.push(false);
          }
        }

        if (inp.valid === false) {
          errors.push(false);
        }
      }
    }

    if (errors.length) {
      // form isn't valid
      valid = false;
      setForm(data);
      setError('Form is incomplete or not valid');
      return false;
    } else {
      // form is valid
      return true;
    }
  }

  async function submit(e) {
    e.preventDefault();
    // submit the form
    let data = { ...form };

    // create the credit card token
    if (processCreditCard) {
      const res = await props.stripe.createToken(props.elements.getElement(CardElement));
      data.token.value = res.error ? res.error : res.token;
    }

    // is the form valid?
    if (!validate()) return false;

    // optimise data for server
    for (let input in form) {
      if (processCreditCard && input === 'token') {
        // procress credit card
        data[input] = form[input].value;
      } else if (input !== 'header') {
        // process single input & ignore headers

        data[input] = form[input].value || form[input].default;
      }
    }

    delete data.header;

    // submit the form or execute callback
    if (!props.url) {
      if (props.callback) props.callback(null);

      return false;
    }

    try {
      // send the form
      setLoading(true);
      let res = await Axios({
        method: props.method,
        url: apiRoot + props.url,
        data: data,
      });

      // check for 2-factor payment requirement
      if (res.data.requires_payment_action) {
        const stripeRes = await props.stripe.handleCardPayment(res.data.client_secret);

        if (stripeRes.error) {
          setLoading(false);
          context.handleError(stripeRes.error.message);
          return false;
        } else {
          // re-send the form
          data.stripe = res.data;
          res = await Axios({
            method: props.method,
            url: apiRoot + props.url,
            data: data,
          });
        }
      }

      // finish loading
      setLoading(false);

      // close the modal
      context.modal.hide(false);

      // callback?
      if (props.callback) props.callback(res);

      // redirect?
      if (props.redirect) History.push(props.redirect);

      // success notification
      if (res.data.message && props.notification)
        context.notification.show(res.data.message, 'success', true);
    } catch (err) {
      // handle error
      setLoading(false);
      context.modal.hide(true);

      // show error on input
      if (err.response?.data?.inputError) {
        let data = { ...form };
        const input = err.response.data.inputError;
        data[input].valid = false;
        data[input].errorMessage = err.response.data.message;
        valid = false;
        setForm(data);
        return false;
      } else {
        // general errors handled by view
        context.handleError(err);
      }
    }
  }

  let inputsToRender = [];

  // map the inputs
  Object.keys(form).map((name) => {
    // get the values for this input
    const data = form[name];
    data.name = name;
    inputsToRender.push(data);
    return inputsToRender;
  });

  // render the form
  // buttons for forms: https://dzello.com/blog/2017/02/19/demystifying-enter-key-submission-for-react-forms/
  return (
    <form action={props.action} method={props.method} onSubmit={(e) => submit(e)} noValidate>
      {inputsToRender.map((input) => {
        if (!input.type) input.type = 'text';

        if (input.type === 'creditcard' && !processCreditCard) return false;

        const Input = Inputs[input.type];
        if (input.type === 'richtext') {
          return (
            <RichTextEditor
              key={input.name}
              label={input.label}
              name={input.name}
              required={input.required}
              onChange={update}
              isValid={input.valid}
              value={input.value}
              default={input.default}
              errorMessage={input.errorMessage}
              maxLength={input.maxLength}
            />
          );
        } else {
          return (
            <Box pl="1px" pr="1px" mb="5px" key={input.name}>
              <Input
                key={input.name}
                type={input.type}
                form={props.name}
                label={input.label}
                className={input.class}
                name={input.name}
                value={input.value}
                required={input.required}
                valid={input.valid}
                min={input.min}
                max={input.max}
                options={input.options}
                default={input.default}
                url={input.url}
                text={input.text}
                title={input.title}
                accept={input.accept}
                description={input.description}
                handleLabel={input.handleLabel}
                placeholder={input.placeholder}
                errorMessage={input.errorMessage}
                onChange={update}
                tabIndex={input.tabindex}
                autoComplete={input.autocomplete}
                complexPassword={input.complexPassword}
                confirmPassword={input.confirmPassword}
                maxLength={input.maxLength}
                restrictWidth={input.restrictWidth}
                hexcolor={input.hexcolor}
              />
            </Box>
          );
        }
      })}

      {!props.hideCancel && props.cancel && (
        <Button variant="orange" width="100%" onClick={props.cancel} type="button" mt="10px">
          Cancel
        </Button>
      )}

      {props.buttonText && (
        <Button isLoading={loading} width="100%" variant="orange" type="submit" mt="10px">
          {props.buttonText}
        </Button>
      )}

      {error && <Error message={error} />}
    </form>
  );
}

// wrap payment form in stripe elements
function PaymentForm(props) {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => <Form stripe={stripe} elements={elements} {...props} />}
    </ElementsConsumer>
  );
}

// export two versions of the form
// 1. is a standard form
// 2. is wrapped in a stripe context
export { Form, PaymentForm };
