import React, { useContext, useState } from 'react';
import { Spinner } from '@chakra-ui/react';
import { ViewContext, Card, Table, Grid, Stat, useAPI } from 'components/lib';

export function AdminFeedback(props) {
  // context & state
  const context = useContext(ViewContext);
  const [reload, setReload] = useState(0);
  const feedback = useAPI('/feedback');

  if (feedback.loading) return <Spinner size="xl" color={'#ec4815'} />;

  if (!feedback.data) return false;

  function deleteFeedback(data, callback) {
    context.modal.show(
      {
        title: 'Delete Feedback Item',
        form: {},
        buttonText: 'Delete ',
        text: 'Are you sure you want to delete this piece of feedback?',
        url: `/feedback/${data.id}`,
        method: 'DELETE',
      },
      (res) => {
        setReload(reload + 1);
        callback();
      }
    );
  }

  return (
    <>
      <Stats reload={reload} />

      <Card>
        <Table
          show={['rating', 'comment']}
          data={feedback?.data}
          actions={{
            delete: deleteFeedback,
            email: true,
          }}
        />
      </Card>
    </>
  );
}

function Stats(props) {
  const stats = useAPI(`/feedback/metrics?reload=${props.reload}`);

  return (
    <Grid cols="3">
      <Stat value={stats?.data?.positive || 0} icon={'smile'} label={'positive'} />
      <Stat value={stats?.data?.neutral || 0} icon={'meh'} label={'neutral'} />
      <Stat value={stats?.data?.negative || 0} icon={'frown'} label={'negative'} />
    </Grid>
  );
}
