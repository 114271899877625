/***
 *
 *   Signups
 *   Enables an owner to manage their signups for each checklist
 *
 **********/

import React, { useState, useContext, useEffect } from 'react';
import { Table, Card, useAPI, ViewContext } from 'components/lib';
import { NavLink } from 'react-router-dom';

export function Signup(props) {
  const context = useContext(ViewContext);
  // State
  const [signupList, setSignupList] = useState([]);
  const [search, setSearch] = useState('');

  // Uses History.state to pass variable from dashboard for View Signups Button
  const [checklist, setChecklist] = useState(props.location.state?.checklist || '');
  const [checklistNames, setChecklistNames] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 25,
    totalPages: 0,
  });

  const signups = useAPI(
    `/signup?page=${pagination.page}&limit=${pagination.limit}&search=${search}&checklist=${checklist}`
  );

  useEffect(() => {
    if (signups.data) {
      setSignupList(signups.data.signupsList);
      setPagination({
        page: pagination.page,
        limit: pagination.limit,
        totalPages: Math.ceil(signups.data.signupsCount / pagination.limit),
      });
      setChecklistNames(signups.data.checklistNames);
    }
  }, [signups.data, pagination.page, pagination.limit]);

  const handleLimit = (name, value) => {
    // Handles limit per page (offset)
    let pageNumber = pagination.page;
    let newPageTotal = Math.ceil(signups.data.signupsCount / value);

    if (pageNumber > newPageTotal && newPageTotal > 0) pageNumber = newPageTotal;
    setPagination({ limit: value, page: pageNumber });
  };

  const handleChecklist = (name, value) => {
    setChecklist(value);
    setSearch('');
  };

  const signupsData = signupList?.map((signup) => {
    return {
      identifier: signup.id,
      name: `${signup.first_name}  ${signup.last_name}`,
      'checklist name': (
        <NavLink to={`/dashboard/checklist/${signup.checklist_id}/steps`}>
          {signup.checklist_name}
        </NavLink>
      ),
      email: signup.email,
      subscribed: signup.subscribed,
      'signup date': signup.date_created,
      checklistId: signup.checklist_id,
    };
  });

  const editSignup = (data, callback) => {
    context.modal.show(
      {
        title: `Edit Signup`,
        form: {
          checklistId: {
            type: 'hidden',
            value: data.checklistId,
          },
          subscribed: {
            label: 'Subscribed',
            type: 'switch',
            value: data.subscribed,
            default: data.subscribed,
          },
        },
        buttonText: `Save`,
        url: `/signup/${data.identifier}`,
        method: 'PATCH',
      },
      (res) => {
        if (res.data) {
          const updatedSignupLists = signupList?.map((signup) => {
            if (signup.id === data.identifier) {
              return res.data.data;
            } else return signup;
          });
          setSignupList(updatedSignupLists);
        }
        context.notification.show(data.email + ' was Updated', 'success', true);
      }
    );
  };

  const deleteSignup = (data, callback) => {
    context.modal.show(
      {
        form: {
          checklistId: {
            type: 'hidden',
            value: data.checklistId,
          },
        },
        title: 'Delete Signup',
        buttonText: 'Delete Signup',
        text: 'Do you want to delete signup ' + data.email + '?',
        url: `/signup/${data.identifier}`,
        method: 'DELETE',
      },
      (res) => {
        if (res.data) {
          const updatedSignupLists = signupList?.filter((signup) => signup.id !== data.identifier);
          setSignupList(updatedSignupLists);
        }
        context.notification.show(data.email + ' was deleted', 'success', true);
      }
    );
  };

  const resetChecklist = (data) => {
    context.modal.show(
      {
        form: {
          checklistId: {
            type: 'hidden',
            value: data.checklistId,
          },
        },
        title: 'Reset Checklist',
        buttonText: 'Reset',
        text:
          'Are you sure you want to uncheck all the steps of the Checklist associated with this Signup?',
        url: `/signup/${data.identifier}/reset`,
        method: 'DELETE',
      },
      (res) => {
        context.notification.show(data.email + ' was deleted', 'success', true);
      }
    );
  };

  return (
    <>
      <Card className="signups-card">
        <Table
          signups={!!signupsData?.length}
          handleChecklist={handleChecklist}
          setSearch={setSearch}
          setPagination={setPagination}
          handleLimit={handleLimit}
          pagination={pagination}
          loading={signups.loading}
          checklistData={{ signups: checklistNames, default: checklist || 'View All' }}
          className="restict-width"
          show={['identifier', 'signup date', 'checklist name', 'name', 'email', 'subscribed']}
          data={signupsData}
          actions={{
            edit: editSignup,
            delete: deleteSignup,
            email: true,
            custom: [{ icon: 'refresh-cw', action: resetChecklist, title: 'Reset Checklist' }],
          }}
        />
      </Card>
    </>
  );
}
