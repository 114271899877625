/***
 *
 *   TABLE
 *   Please view the full documentation: https://docs.usegravity.app/ui/table
 *
 *   PROPS
 *   data: array of objects for body and header (optional)
 *   search: bool to show the search field
 *   sort: allow the table columns to be sorted
 *   loading: bool to toggle loading spinner
 *   badge - object containing column name and color to add badges to column
 *   show - array of columns (object key names) to show (shows all if not provided)
 *   hide - array of columns (object key names) to hide
 *   actions: object with edit/delete keys set to callback functions (optional)
 *
 **********/

import React, { useState, useEffect } from 'react';
import { Spinner } from '@chakra-ui/react';
import { Flex, Search, Select, Pagination } from 'components/lib';
import { Header } from './header';
import { Body } from './body';
import Style from './table.module.scss';

export function Table(props) {
  // state
  const [header, setHeader] = useState(null);
  const [body, setBody] = useState(null);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    if (props.data) {
      // create the headers
      let header = props.data.header || [];

      if (!header.length) {
        for (let key in props.data[0]) {
          header.push({
            name: key,
            title: key.replace('_', ' '),
            sort: key === 'actions' ? false : true,
          });
        }
      }

      setBody(props.data);
      setHeader(header);
    }
  }, [props.data]);

  // loading
  if (props.loading) {
    return (
      <div className={Style.loading}>
        <Spinner size="xl" color={'#ec4815'} />
      </div>
    );
  }

  // no data
  if (!header && !body) return false;

  function sort(column, direction) {
    const rows = filter.length ? [...filter] : [...body];

    rows.sort(function (a, b) {
      if (a[column] && b[column]) {
        a[column].badge ? (a = a[column].label) : (a = a[column]);

        b[column].badge ? (b = b[column].label) : (b = b[column]);

        if (direction === 'desc') {
          if (a > b) return -1;
          if (a < b) return 1;
          else return 0;
        } else {
          if (a < b) return -1;
          if (a > b) return 1;
          else return 0;
        }
      } else {
        return false;
      }
    });

    filter ? setFilter(rows) : setBody(rows);
  }

  function search(term) {
    // search each cell in each row &
    // update state to show only filtered rows
    let rowsToShow = [];

    body.forEach((row) => {
      for (let cell in row) {
        if (row[cell]?.toString().toLowerCase().includes(term.toLowerCase())) {
          if (!rowsToShow.includes(row)) rowsToShow.push(row);
        }
      }
    });

    setFilter(rowsToShow);
  }

  function deleteRow(data) {
    // call delete prop, then update state
    props.actions.delete(data, () => {
      let state = [...body];
      state.splice(
        state.findIndex((x) => x.id === data.id),
        1
      );
      setBody(state);
    });
  }

  function editRow(data) {
    // call edit prop and update row
    props.actions.edit(data, (res) => {
      let state = [...body];
      let row = state[state.findIndex((x) => x.id === data.id)];
      Object.keys(res).map((key) => (row[key] = res[key].value));
    });
  }

  return (
    <>
      {props.search && <Search className={Style.search} callback={search} />}
      {props.setSearch && <Search className={Style.search} callback={props.setSearch} />}
      {props.checklistData?.signups && (
        <Flex align="flex-end">
          <div className="page-select">
            <Select
              label="Checklists"
              onChange={props.handleChecklist}
              text="checklists-select"
              name="checklists-select"
              options={props.checklistData.signups}
              default={props.checklistData.default}
            ></Select>
          </div>
          <div className="page-select">
            <Select
              label="Per Page"
              onChange={props.handleLimit}
              text="limit"
              name="limit"
              default={props.pagination?.limit || 25}
              options={[
                { value: 25, label: 25 },
                { value: 50, label: 50 },
                { value: 100, label: 100 },
                { value: 500, label: 500 },
                { value: 1000, label: 1000 },
              ]}
            ></Select>
          </div>
        </Flex>
      )}
      {props.checklistData?.reminders && (
        <Flex align="flex-end">
          <div className="page-select">
            <Select
              label="Checklists"
              onChange={props.handleChecklist}
              text="checklists-select"
              name="checklists-select"
              options={props.checklistData.reminders}
              default={props.checklistData.default}
            ></Select>
          </div>
          <div className="page-select">
            <Select
              label="Per Page"
              onChange={props.handleLimit}
              text="limit"
              name="limit"
              default={props.pagination?.limit || 25}
              options={[
                { value: 25, label: 25 },
                { value: 50, label: 50 },
                { value: 100, label: 100 },
                { value: 500, label: 500 },
                { value: 1000, label: 1000 },
              ]}
            ></Select>
          </div>
        </Flex>
      )}
      {props.exclusions && (
        <Flex align="flex-end">
          <div className="page-select">
            <Select
              label="Per Page"
              onChange={props.handleLimit}
              text="limit"
              name="limit"
              default={props.pagination?.limit || 25}
              options={[
                { value: 25, label: 25 },
                { value: 50, label: 50 },
                { value: 100, label: 100 },
                { value: 500, label: 500 },
                { value: 1000, label: 1000 },
              ]}
            ></Select>
          </div>
        </Flex>
      )}
      <table className={`${!props.naked && Style.table} ${props.className} checklist-table`}>
        {header && (
          <Header
            data={header}
            callback={sort}
            show={props.show}
            hide={props.hide}
            actions={props.actions}
          />
        )}
        {body && (
          <Body
            toggle={props.toggle}
            reminders={props.reminders}
            data={filter ? filter : body}
            show={props.show}
            hide={props.hide}
            badge={props.badge}
            actions={{
              edit: props.actions?.edit && editRow,
              delete: props.actions?.delete && deleteRow,
              testEmail: props.actions?.testEmail,
              email: props.actions?.email,
              custom: props.actions?.custom,
            }}
          />
        )}
      </table>
      {props.signups && (
        <Pagination pagination={props.pagination} setPagination={props.setPagination} />
      )}
      {props.exclusions && (
        <Pagination pagination={props.pagination} setPagination={props.setPagination} />
      )}
      {props.reminders && (
        <Pagination pagination={props.pagination} setPagination={props.setPagination} />
      )}
    </>
  );
}
